import { createRouter, createWebHistory } from '@ionic/vue-router';
import { getAuth } from "firebase/auth";

const routes = [
  {
    path: '/',
    redirect: '/tabs/users'
  },
  {
    path: '/test',
    component: () => import('@/views/TestPage.vue'),
    meta: {
      title: 'Test'
    }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/LoginPage.vue'),
    meta: {
      title: 'Login'
    }
  },
  {
    path: '/user/:id',
    name: 'user',
    component: () => import('@/views/users/UserViewPage.vue'),
    meta: {
      requiresAuth: true,
      title: 'User'
    }
  },
  {
    path: '/message/:id',
    name: 'message-view',
    component: () => import('@/views/messages/MessageViewPage.vue'),
    meta: {
      requiresAuth: true,
      title: 'message'
    }
  },
  {
    path: '/transaction/:id',
    name: 'transaction-view',
    component: () => import('@/views/transactions/TransactionViewPage.vue'),
    meta: {
      requiresAuth: true,
      title: 'Transaction'
    }
  },
  {
    path: '/withdrawal/:id',
    name: 'withdrawal-view',
    component: () => import('@/views/withdrawals/WithdrawalViewPage.vue'),
    meta: {
      requiresAuth: true,
      title: 'withdrawal'
    }
  },
  {
    path: '/create/signals',
    name: 'post-signals',
    component: () => import('@/views/signals/PostSignals.vue'),
    meta: {
      requiresAuth: true,
      title: 'Post Signals'
    }
  },
  {
    path: '/create/past-trades',
    name: 'post-past-trades',
    component: () => import('@/views/past-trades/PostPastTrades.vue'),
    meta: {
      requiresAuth: true,
      title: 'Post Past trades'
    }
  },
  {
    path: '/create/course-video/:type',
    name: 'post-video',
    component: () => import('@/views/courses/PostCourseVideo.vue'),
    meta: {
      requiresAuth: true,
      title: 'Post Video'
    }
  },
  {
    path: '/edit/course/:type',
    name: 'edit-course',
    component: () => import('@/views/courses/CoursePage.vue'),
    meta: {
      requiresAuth: true,
      title: 'Course page'
    }
  },
  {
    path: '/edit/price/:type',
    name: 'edit-price',
    component: () => import('@/views/update/UpdatePricePage.vue'),
    meta: {
      requiresAuth: true,
      title: 'edit price'
    }
  },
  {
    path: '/edit/course-video/:type/:id',
    name: 'edit-course-video',
    component: () => import('@/views/courses/EditCourse.vue'),
    meta: {
      requiresAuth: true,
      title: 'course video'
    }
  },
  {
    path: '/edit/signals/:id',
    name: 'edit-signals',
    component: () => import('@/views/signals/EditSignals.vue'),
    meta: {
      requiresAuth: true,
      title: 'Edit Signals'
    }
  },
  {
    path: '/edit/Past-trades/:id',
    name: 'edit-Past-trades',
    component: () => import('@/views/past-trades/EditPastTrades.vue'),
    meta: {
      requiresAuth: true,
      title: 'Edit Past-trades'
    }
  },
  {
    path: '/tabs/signals',
    name: 'signals',
    component: () => import('@/views/signals/SignalsPage.vue'),
    meta: {
      title: 'Signals',
      requiresAuth: true
    }
  },
  {
    path: '/tabs/messages',
    name: 'messages',
    component: () => import('@/views/messages/MessagesPage.vue'),
    meta: {
      title: 'messages',
      requiresAuth: true
    }
  },
  {
    path: '/tabs/transactions',
    name: 'transactions',
    component: () => import('@/views/transactions/TransactionsPage.vue'),
    meta: {
      title: 'transactions',
      requiresAuth: true
    }
  },
  {
    path: '/tabs/withdrawals',
    name: 'withdrawals',
    component: () => import('@/views/withdrawals/WithdrawalsPage.vue'),
    meta: {
      title: 'withdrawals',
      requiresAuth: true
    }
  },
  {
    path: '/tabs/past-trades',
    name: 'past-trades',
    component: () => import('@/views/past-trades/PastTradesPage.vue'),
    meta: {
      title: 'past-trades',
      requiresAuth: true
    }
  },
  {
    path: '/tabs/update-price',
    name: 'update',
    component: () => import('@/views/update/UpdatePriceList.vue'),
    meta: {
      title: 'update',
      requiresAuth: true
    }
  },
  {
    path: '/tabs/courses',
    name: 'courses',
    component: () => import('@/views/courses/MembershipCoursesPage.vue'),
    meta: {
      title: 'Membership-courses',
      requiresAuth: true
    }
  },
  {
    path: '/courses/:type',
    name: 'courses-view',
    component: () => import('@/views/courses/MembershipCoursesViewPage.vue'),
    meta: {
      title: 'Membership-courses-view',
      requiresAuth: true
    }
  },
  {
    path: '/tabs/users',
    name: 'users',
    component: () => import('@/views/users/UsersPage.vue'),
    meta: {
      title: 'Users',
      requiresAuth: true
    }
  },
  {
    path: '/tabs/push-messages',
    name: 'pushMessages',
    component: () => import('@/views/PushMessagesPage.vue'),
    meta: {
      title: 'Push messages',
      requiresAuth: true
    }
  },
  {
    path: '/tabs/push-notifications',
    name: 'pushNotifications',
    component: () => import('@/views/PushNotificationsPage.vue'),
    meta: {
      title: 'Push notifications',
      requiresAuth: true
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  document.title = `${to.meta.title} | Admin-Avalanche-wealth`;

  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);

  const auth = getAuth();
  const isAuthenticated = auth.currentUser

  // check if user is logged in auth.currrentUser and add it to to requiresAuth
  if(requiresAuth && !isAuthenticated) {
    next('/login')
  } else {
    next();
  }
  
})

export default router
