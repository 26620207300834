<template>
  <div id="container">
    
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import {  } from '@ionic/vue';
  
export default defineComponent({
  name: 'ContainerCard',
});
</script>

<style scoped>
#container {
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 80%;
  min-height: 300px;
  margin: auto auto;
  background-color: aliceblue;
  display: flex;
  align-items: center;
  justify-content: center;
}

#container strong {
  font-size: 20px;
  line-height: 26px;
}

#container p {
  font-size: 16px;
  line-height: 22px;
  color: #8c8c8c;
  margin: 0;
}

#container a {
  text-decoration: none;
}
</style>
