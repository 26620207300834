import { initializeApp } from "firebase/app";
import { getFirestore, Timestamp } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBTF4mwlOIWFbGlEG-PLQTNrmTmkV9G1xk",
  authDomain: "digital-wealth-app.firebaseapp.com",
  projectId: "digital-wealth-app",
  storageBucket: "digital-wealth-app.appspot.com",
  messagingSenderId: "826562537908",
  appId: "1:826562537908:web:813feee79c5deb73dfeae1",
  measurementId: "G-NVHDXGYTR2"
};

// initializeApp(firebaseConfig);

const firebase = initializeApp(firebaseConfig);

const db = getFirestore(firebase);

export { db, firebase, Timestamp }