<template>
  <ion-app>
    <template v-if="loading">
      <div class="loading-center">
        <ion-spinner color="primary"></ion-spinner>
      </div>
    </template>
    <template v-else-if="noAccess">
      <div class="bg">
        <ion-img src="assets/vectors/denied.svg"></ion-img>
        <div class="center">
          <h1 class="ion-text-center">
            You don't have access to this portal!
          </h1>
        </div>
        <ion-row class="center">
          <ion-button fill="outline" @click="this.$router.go()" class="ion-text-center" type="submit">
            Try again
          </ion-button>
        </ion-row>
      </div>
    </template>
    <template v-else-if="noInternet">
      <div class="bg">
        <ion-img src="assets/vectors/cancel.svg"></ion-img>
        <div class="center">
          <h1 class="ion-text-center">
            It seems you're not connected to the internet!
          </h1>
        </div>
        <ion-row class="center">
          <ion-button fill="outline" @click="this.$router.go()" class="ion-text-center" type="submit">
            Try again
          </ion-button>
        </ion-row>
      </div>
    </template>
    <template v-else>
      <ion-split-pane content-id="main-content">
        <ion-menu content-id="main-content" type="overlay">
          <ion-content>
            <ion-list id="inbox-list">
              <ion-list-header>Admin</ion-list-header>
              <ion-note>admin@avalanchewealth.com</ion-note>
    
              <ion-menu-toggle auto-hide="false" v-for="(p, i) in appPages" :key="i">
                <ion-item @click="selectedIndex = i" router-direction="root" :router-link="p.url" lines="none" detail="false" class="hydrated" :class="{ selected: selectedIndex === i }">
                  <ion-icon slot="start" :ios="p.iosIcon" :md="p.mdIcon"></ion-icon>
                  <ion-label>{{ p.title }}</ion-label>
                </ion-item>
              </ion-menu-toggle>
            </ion-list>
    
            <ion-list id="labels-list">
    
              <ion-item v-for="(label, index) in labels" lines="none" :key="index" @click="logoutHandler()">
                <ion-icon slot="start" :ios="logOutOutline" :md="logOut"></ion-icon>
                <ion-label>{{ label }}</ion-label>
              </ion-item>
            </ion-list>
          </ion-content>
        </ion-menu>
        <ion-router-outlet id="main-content"></ion-router-outlet>
      </ion-split-pane>
    </template>
  </ion-app>
</template>

<script>
import { IonApp, IonContent, IonIcon, IonItem, IonLabel, IonList, IonListHeader, IonMenu, IonMenuToggle, IonNote, IonRouterOutlet, IonSplitPane, useBackButton, useIonRouter, IonImg, IonRow, IonButton, IonSpinner } from '@ionic/vue';
import { defineComponent, ref } from 'vue';
import { useRoute } from 'vue-router';
import { bookOutline, bookSharp, logOutOutline, logOut, chatbubblesOutline, chatbubblesSharp, peopleCircleOutline, peopleCircle, cashOutline, cashSharp, analyticsOutline, analyticsSharp, create, createOutline, list, listOutline, walletOutline, walletSharp, notifications, notificationsOutline, chatbox, chatboxOutline } from 'ionicons/icons';
import { StatusBar, Style } from '@capacitor/status-bar';
import { mapActions, mapGetters } from 'vuex';
import { Plugins } from '@capacitor/core'
import { PushNotifications } from "@capacitor/push-notifications";
const { App } = Plugins;
import { FCM } from '@capacitor-community/fcm'

const fcm = FCM

export default defineComponent({
  name: 'App',
  components: {
    IonApp, 
    IonContent, 
    IonIcon, 
    IonItem, 
    IonLabel, 
    IonList, 
    IonListHeader, 
    IonMenu, 
    IonMenuToggle, 
    IonNote, 
    IonRouterOutlet, 
    IonSplitPane,
    IonImg,
    IonRow,
    IonButton,
    IonSpinner
  },
  data() {
    return {
      loading: true,
      noInternet: false,
      noAccess: false
      // fcm: new FCM()
    }
  },
  computed: mapGetters(['currentUser']),
  mounted() {
    // Request permission to use push notifications
    // iOS will prompt user and return if they granted permission or not
    // Android will just grant without prompting
    PushNotifications.requestPermission().then((result) => {
      alert("result " + JSON.stringify(result));
    });
     // Add registration error if there are.
    PushNotifications.addListener("registrationError", (error) => {
      console.log(`error on register ${JSON.stringify(error)}`);
    }),
    // Add Notification received
    PushNotifications.addListener(
      "pushNotificationReceived",
      (notification) => {
        console.log(`notification ${JSON.stringify(notification)}`);
      }
    ),
    // Add Action performed
    PushNotifications.addListener(
      "pushNotificationActionPerformed",
      async (notification) => {
          alert("notification " + notification)
        console.log("notification succeeded");
      }
    ),
    // Initialize the registration with FCM Token
    PushNotifications.register();
    const fcmToken = fcm.getToken();
    // alert(JSON.stringify(fcmToken));
    console.log("token:" + JSON.stringify(fcmToken));
  },
  methods: {
    ...mapActions(['fetchCurentUserDetails', 'logout', 'checkLogin']),
    async logoutHandler()  {
      if(await this.logout() == true){
        location.reload();
      }
    }
  },
  async created() {
    const setStatusBarStyleLight = async () => {
      await StatusBar.setStyle({ style: Style.Light });
    };

    setStatusBarStyleLight()

    if(await this.checkLogin() == true) {
      if(!this.currentUser) {
        await this.fetchCurentUserDetails()
        .then(() => {
          if(this.currentUser.isAdmin) {
            this.loading = false
          } else {
            alert(`You don't have access to this portal`)
            this.noAccess = true
            this.loading = false
          }
        })
        .catch((err) => {
          console.log(err)
          this.noInternet = true
          this.loading = false
        })
      }
    } else {
      this.loading = false
    }


  },
  setup() {
    const ionRouter = useIonRouter();
    useBackButton( -1, () => {
        if (!ionRouter.canGoBack()) {
          if(confirm('are you sure want to exit app.') == true) {
            App.exitApp();
          }
        }
    })
    const selectedIndex = ref(0);
    const appPages = [
      {
        title: 'Users',
        url: '/tabs/Users',
        iosIcon: peopleCircleOutline,
        mdIcon: peopleCircle
      },
      {
        title: 'Signals',
        url: '/tabs/Signals',
        iosIcon: analyticsOutline,
        mdIcon: analyticsSharp
      },
      {
        title: 'Courses',
        url: '/tabs/courses',
        iosIcon: bookOutline,
        mdIcon: bookSharp
      },
      {
        title: 'Messages',
        url: '/tabs/messages',
        iosIcon: chatbubblesOutline,
        mdIcon: chatbubblesSharp
      },
      {
        title: 'Transactions',
        url: '/tabs/transactions',
        iosIcon: cashOutline,
        mdIcon: cashSharp
      },
      {
        title: 'Withdrawals',
        url: '/tabs/withdrawals',
        iosIcon: walletOutline,
        mdIcon: walletSharp
      },
      {
        title: 'Past Trades',
        url: '/tabs/past-trades',
        iosIcon: listOutline,
        mdIcon: list
      },
      {
        title: 'update Price/Earnings',
        url: '/tabs/update-price',
        iosIcon: createOutline,
        mdIcon: create
      },
      {
        title: 'Push Notifications',
        url: '/tabs/push-notifications',
        iosIcon: notificationsOutline,
        mdIcon: notifications
      },
      {
        title: 'Push Messages',
        url: '/tabs/push-messages',
        iosIcon: chatboxOutline,
        mdIcon: chatbox
      }
    ];
    const labels = ['logout'];
    
    const path = window.location.pathname.split('tabs/')[1];
    if (path !== undefined) {
      selectedIndex.value = appPages.findIndex(page => page.title.toLowerCase() === path.toLowerCase());
    }
    
    const route = useRoute();
    
    return { 
      selectedIndex,
      appPages, 
      labels,
      bookOutline, 
      bookSharp, 
      logOutOutline, 
      logOut, 
      chatbubblesOutline, 
      chatbubblesSharp, 
      peopleCircleOutline, 
      peopleCircle, 
      analyticsOutline, 
      analyticsSharp, 
      cashOutline, 
      cashSharp, 
      create,
      createOutline,
      list,
      listOutline,
      walletOutline,
      walletSharp,
      notifications,
      notificationsOutline,
      chatbox,
      chatboxOutline,
      isSelected: (url) => url === route.path ? 'selected' : ''
    }
  }
});
</script>

<style scoped>
ion-menu ion-content {
  --background: var(--ion-item-background, var(--ion-background-color, #fff));
}

ion-menu.md ion-content {
  --padding-start: 8px;
  --padding-end: 8px;
  --padding-top: 20px;
  --padding-bottom: 20px;
}

ion-menu.md ion-list {
  padding: 20px 0;
}

ion-menu.md ion-note {
  margin-bottom: 30px;
}

ion-menu.md ion-list-header,
ion-menu.md ion-note {
  padding-left: 10px;
}

ion-menu.md ion-list#inbox-list {
  border-bottom: 1px solid var(--ion-color-step-150, #d7d8da);
}

ion-menu.md ion-list#inbox-list ion-list-header {
  font-size: 22px;
  font-weight: 600;

  min-height: 20px;
}

ion-menu.md ion-list#labels-list ion-list-header {
  font-size: 16px;

  margin-bottom: 18px;

  color: #757575;

  min-height: 26px;
}

ion-menu.md ion-item {
  --padding-start: 10px;
  --padding-end: 10px;
  border-radius: 4px;
}

ion-menu.md ion-item.selected {
  --background: rgba(var(--ion-color-primary-rgb), 0.14);
}

ion-menu.md ion-item.selected ion-icon {
  color: var(--ion-color-primary);
}

ion-menu.md ion-item ion-icon {
  color: #616e7e;
}

ion-menu.md ion-item ion-label {
  font-weight: 500;
}

ion-menu.ios ion-content {
  --padding-bottom: 20px;
}

ion-menu.ios ion-list {
  padding: 20px 0 0 0;
}

ion-menu.ios ion-note {
  line-height: 24px;
  margin-bottom: 20px;
}

ion-menu.ios ion-item {
  --padding-start: 16px;
  --padding-end: 16px;
  --min-height: 50px;
}

ion-menu.ios ion-item.selected ion-icon {
  color: var(--ion-color-primary);
}

ion-menu.ios ion-item ion-icon {
  font-size: 24px;
  color: #73849a;
}

ion-menu.ios ion-list#labels-list ion-list-header {
  margin-bottom: 8px;
}

ion-menu.ios ion-list-header,
ion-menu.ios ion-note {
  padding-left: 16px;
  padding-right: 16px;
}

ion-menu.ios ion-note {
  margin-bottom: 8px;
}

ion-note {
  display: inline-block;
  font-size: 16px;

  color: var(--ion-color-medium-shade);
}

ion-item.selected {
  --color: var(--ion-color-primary);
}
.bg {
  display: flex;
  flex-direction: column;
  height: 90vh;
  width: 100vw;
  justify-content: center;
  align-items: center;
  background-color: white;
}
</style>
